<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="formInline.time"
            clearable
            type="daterange"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          <el-button type="primary" v-if="tableData.length>0" @click="exportXcle" icon="el-icon-download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox">
      <el-table
      :data="tableData"
      style="width: 100%"
      id="exportTable"
      :header-cell-style="{fontWeight: '700', fontSize: '14px'}"
      >
      <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
      <el-table-column prop="communityName" label="社区" align="center"></el-table-column>
      <el-table-column prop="shopCount" label="推荐商户数量" align="center"></el-table-column>
      <el-table-column prop="points" label="推荐商户消费总积分" align="center"></el-table-column>
      <el-table-column prop="userCount" label="推荐商户消费总人数" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { communityConsumeOverviewList, exportExcel } from '@/api/reportStatistics'
export default {
  data() {
    return {
      choiceDate0: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate:
        (time) => {
          // let choiceDateTime = new Date(this.choiceDate0).getTime();
          // const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
          // const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
          // const min = minTime;
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          // const max = newDate < maxTime ? newDate : maxTime;
          // //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          // if (this.choiceDate0) {
          //   return time.getTime() < min || time.getTime() > max;
          // }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      formInline: {
        time: '',
      },
      tableData: [],
      headLabel: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    onSearch() {
      this.getList();
    },
    //获取商户近一个月每天获取积分
    getList() {
      let begintime = "";
      let endtime = "";
      if(this.formInline.time) {
        begintime = this.formInline.time[0];
        endtime = this.formInline.time[1];
      }
      communityConsumeOverviewList({
        begintime: begintime,
        endtime: endtime
      }).then( res => {
        if(res.result == 200) {
          this.tableData = res.detail;
        }
      })
    },
    //导出表格
    exportXcle() {
      let begintime = "";
      let endtime = "";
      if(this.formInline.time) {
        begintime = this.formInline.time[0];
        endtime = this.formInline.time[1];
      }
      exportExcel({
        type: 4,
        begintime: begintime,
        endtime: endtime
      }).then(res => {
        const content = res;
        const blob = new Blob([content]) //构造一个blob对象来处理数据
        const fileName =  '沿江街道各社区推荐商户情况统计.xlsx';
        const link = document.createElement('a') //创建a标签
        link.download = fileName //a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click() //执行下载
        URL.revokeObjectURL(link.href) //释放url
        document.body.removeChild(link) //释放标签
      })
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
